window.addEventListener("load", add_goodies);
var _ = require("lodash")

function add_goodies() {
  var q = document.querySelectorAll(".help, .close")
  var hm = document.querySelector(".help-modal")

  var fullheight = (window.innerHeight > document.body.clientHeight) ? window.innerHeight : document.body.clientHeight

  hm.style.height = fullheight + "px"

  window.addEventListener("keydown", function(e) {
    if (e.key == "Escape") toggle(hm)
  })

  // console.log("extras here", q, hm)
  q.forEach(function(el) {
    el.addEventListener("click", function(e) {
      toggle(hm)
    })
  })

  function toggle(el) {
    var state =  _.includes(el.classList, "displaynone")
    // console.log("classlist", state)
    if (state) {
      el.classList.remove("displaynone")
      _.delay(function() {el.style.opacity = 1}, 1)
    }
    else {
      // el.classList.remove("opacity1")
      el.style.opacity = 0
      _.delay(function() {el.classList.add("displaynone")}, 100)
    }
  }
}
