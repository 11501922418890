var d3 = require('d3')
var _ = require('lodash')
var d3_gp = require('d3-geo-projection')
var topojson = require('topojson')

var debug = true;

console.log("it's all happening!");

console.log("hello?")

var app = {
  settings: {
    dotradius: 6,
    keyframedistance: 1000
  },
  parchment: d3.select(".parchment")
}

app.ishome = document.body.classList.contains("pagetitle-map")
app.current_slug = _.find(document.body.classList, function(el, ix) { return el.search("pagetitle") != -1 }).replace("pagetitle-", "")
if (debug) console.log("current slug:", app.current_slug)

loaddata()

function render() {
  if (debug) console.log("rendering")

  var parched = app.ishome ? document.querySelector(".parchment") : document.querySelector("header")
  if (debug) console.log("parch", parched);
  app.vw = parched.clientWidth
  app.vh = parched.clientHeight
  if (debug) console.log("viewport dimensions", app.vw, app.vh);



  app.postdata = _.sortBy(app.postdata, function(el) {
    var hot_date = new Date(el.date)
    return hot_date
  })

  var countries = topojson.feature(app.mapdata, app.mapdata.objects.countries).features;

  var getfocused = function(which) {
    var focal
    if (which == "first") focal = _.first(app.postdata)
    else if (which == "last") focal = _.last(app.postdata)
    else {
      focal = _.find(app.postdata, ["slug", which])
    }

    return {
      post: focal,
      loc: [getloc(focal, "lng"), getloc(focal, "lat")]
    }
  }

  var start = function(c) {
    if (c == "map") return getfocused("first")
    else if (c == "list" || c == "") return getfocused("last")
    else return getfocused(c)
  }

  if (debug) console.log("start focus:", getloc(start(app.current_slug).post, "name"))
  if (debug) console.log("override location?", start(app.current_slug).post.acf.override_google_location)

  var scale = app.ishome ? 600 : 1200
  if (debug) console.log("scale", scale);

  var projection = d3.geoMercator()
      .center(start(app.current_slug).loc)
      .scale(scale)
		  .translate([app.vw/2,app.vh/2])



  function getloc(post, aspect) {
    var brute = post.acf.override_google_location
    var lat = brute ? post.acf.latitude : post.acf.gloc.lat,
        lng = brute ? post.acf.longitude : post.acf.gloc.lng,
        name = brute ? post.acf.place_name : post.acf.gloc.address

    switch(aspect) {
    case "lat":
      return lat
      break
    case "lng":
      return lng
      break
    case "name":
      return name
      break
    }
  }

  function getcoordinate(post, which) {
    var pair = projection([getloc(post, "lng"), getloc(post, "lat")])
    return pair[which]
  }

  var zoom = d3.zoom()
      .scaleExtent([1 / 2, 10])
      .on("zoom", zoomed)

  app.parchment.call(zoom)



  function zoomed() {
    var g = d3.select("g.map-data")
    // if (debug) console.log(d3.event.transform);
    g.attr("transform", d3.event.transform)
  }

  var mappath = d3.geoPath()
      .projection(projection)

  app.mapstuff = app.parchment.append("g").attr("class", "map-data")

  app.mapstuff.append("g")
    .attr("class", "countries")
    .selectAll("path")
    .data(countries)
    .enter().append("path")
    .attr("class", "country")
    .attr("vector-effect", "non-scaling-stroke")
  // .attr("id", function(d) {return d.properties.name})
    .attr("d", mappath)

  var overgroup = app.mapstuff.append("g")
      .attr("class", "overlay")

  var throughline = d3.line()
      .x(function(d) { return getcoordinate(d, 0) })
      .y(function(d) { return getcoordinate(d, 1) })

  var linepath = overgroup.append("path")
      .attr("class", "throughline")
      .attr("d", throughline(app.postdata))

  var linelength = linepath.node().getTotalLength();

  if (app.ishome) linepath
    .attr("stroke-dasharray", linelength + " " + linelength)
    .attr("stroke-dashoffset", linelength)
    .transition()
    .duration(app.postdata.length * app.settings.keyframedistance)
    .ease(d3.easeLinear)
    .attr("stroke-dasharray", linelength + " " + linelength)
    .attr("stroke-dashoffset", 0)



  var datadot = overgroup.selectAll("g")
      .data(app.postdata)
      .enter().append("a")
      .attr("href", function(d) { return d.link })
      .attr("xlink:href", function(d) { return d.link })
      .append("g")
      .attr("class", function(d) { return "datapoint " + d.slug })
      .append("circle")
      .attr("class", "post-dot")
      .attr("r", app.settings.dotradius)
      .attr("cx", function(d) {return getcoordinate(d, 0)})
      .attr("cy", function(d) {return getcoordinate(d, 1)})

  if (app.ishome) datadot.transition()
    .duration(500)
    .delay(function(d, i) {
      return i*app.settings.keyframedistance
    })
    .on("start", function(d, i) {
      var p = [getcoordinate(d, 0), getcoordinate(d, 1)]

      var transitor = app.parchment.transition()
          .duration(app.settings.keyframedistance/2)
          .ease(d3.easeCubicOut)

      zoom.translateTo(transitor, p[0], p[1]);
    })
    .style("opacity", 1)

  else {
    datadot.style("opacity", 1)
    var thisdot = d3.select("." + app.current_slug)
    thisdot.classed("current", true)
    if (debug) console.log(thisdot)

    thisdot.call(function(d) {
      if (debug) console.log("this data", d)
      // var p = [getcoordinate(d, 0), getcoordinate(d, 1)]
      // if (debug) console.log("p", p)
      if (debug) console.log("parchment", app.parchment)
      // zoom.translateTo(app.parchment, p[0], p[1])
    })

  }

  var datapoints = overgroup.selectAll("g")

  datapoints.append("text")
    .attr("class", "label")
    .attr("x", function(d) { return getcoordinate(d, 0) + 20 })
    .attr("y", function(d) { return getcoordinate(d, 1) })
    .attr("dy", ".265em")
    .text(function(d) { return getloc(d, "name") })

  datapoints.on("mouseenter", function(d, i) {
    var label = d3.select(this).select(".label")

    label.transition()
      .duration(200)
      .style("display", "block")
      .style("opacity", "1")

    app.parchment.selectAll(".throughline, .datapoint:not(." + d.slug + ")")
        .style("opacity", "0.3")
  })

  datapoints.on("mouseleave", function(d, i) {
    var label = d3.select(this).select(".label")

    if (!_.includes(d3.select(this).attr("class"), "current")) {
                            label.transition()
      .duration(1000)
      .on("end", function() {
        d3.select(this).style("display", "none")
      })
        .style("opacity", "0")
    }

    app.parchment.selectAll(".throughline, .datapoint:not(." + d.slug + ")")
        .style("opacity", "1")
  })

  datapoints.on("click", function(d, i) {
    if (debug) console.log(d.link)

  })

  // if (debug) console.log(datapoints);
}

function loaddata() {
  if (debug) console.log("loading...!")

  // high res:
  // /wp-content/themes/mondomundo/assets/worldatlas-topojson-50m.json

  // low res:
  // /wp-content/themes/mondomundo/assets/worldatlas-topojson.json

  var files = ["/wp-content/themes/mondomundo/assets/worldatlas-topojson-50m.json",
               "/wp-json/wp/v2/posts?per_page=100"];
  var promises = [];

  files.forEach(function(url) {
    promises.push(d3.json(url))
  });


  Promise.all(promises).then(function(values) {
    drawmap(values)
  });
}

function drawmap(goodies) {
  if (debug) console.log(app);
  app.mapdata = goodies[0]
  app.postdata = goodies[1]
  render()
}


// function get_mapdata() {
//   var countrydata_loc = "../wp-content/themes/mondomundo/assets/worldatlas-topojson-50m.json"

//   d3.json(countrydata_loc)
//     .then(function(data) {
//       if (debug) console.log("got some data", data)
//       app.mapdata = data
//       app.status.mapdataloaded = true
//       // render()
//     }, function(error) {
//       if (debug) console.log("error", error)
//     })
// }

// function get_postdata() {
//   d3.json(window.location.href + "/wp-json/wp/v2/posts")
//     .then(function(data) {
//       if (debug) console.log(data)
//     })
// }
